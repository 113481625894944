/* eslint-disable @next/next/no-img-element */

import { HTMLProps } from 'react';

import { Provider } from '@healthhub/api-lib';
import { ClockIcon, MapPinIcon } from '@heroicons/react/20/solid';

import {
  Button,
  EventName,
  SHOW_FAVOURITES,
  clsxMerge,
  formatAddressToCityAndProvinceOnly,
  formatCompleteAddress,
  trackEvent,
  useRouter,
} from '@mwell-healthhub/commons';
import ProviderHelper from '@mwell-healthhub/commons/utils/ProviderHelper';

import FavouriteBadge from './FavouriteBadge';
import { Routes } from '../../constants';
import { useIsMobile } from '../../hooks';

interface Props extends HTMLProps<HTMLButtonElement> {
  provider: Provider & {
    distanceInKm?: number;
  };
  isProviderFavourite?: boolean;
}

function ProviderCard({ provider, className, isProviderFavourite = false }: Props) {
  const isMobile = useIsMobile();
  const router = useRouter();

  const { logoUrl } = new ProviderHelper(provider);
  const { address, branch, displayName, distanceInKm, name, officeHours } = provider;

  const providerImage = logoUrl ?? '/static-assets/images/placeholder-image.png';
  const formattedDistance = distanceInKm?.toFixed(1);

  const handleGoToClinic = () => {
    trackEvent(EventName.CLICK_VIEW_SERVICE, {
      providerId: provider.id,
      providerName: provider.displayName,
    });

    router.push({
      pathname: Routes.PROVIDER.replace(':id', provider.id?.toString()),
      query: { step: 1 },
    });
  };

  const mobileView = (
    <button
      className={clsxMerge(
        'relative mb-4 flex w-full overflow-hidden rounded-lg border border-neutral-300 bg-white text-left shadow-providerCard',
        className,
      )}
      onClick={handleGoToClinic}
      data-cy="provider-card"
    >
      {SHOW_FAVOURITES && isProviderFavourite && (
        <div className="absolute right-1">
          <FavouriteBadge />
        </div>
      )}

      <div
        className="relative aspect-square h-full min-w-[100px] bg-gray-100 bg-cover bg-center"
        style={{
          backgroundImage: `url(${providerImage})`,
        }}
      />
      <div className="flex w-[70%] flex-col p-2 px-3">
        <p className="mb-1 truncate text-sm font-semibold text-primary-500">{displayName}</p>
        <p className="mb-1 truncate text-xs font-medium">{branch}</p>
        <p className="mb-1 truncate text-xs text-neutral-500">{officeHours ?? ''}</p>
        <p className="truncate text-xs text-neutral-500" title={formatCompleteAddress(address)}>
          {!!formattedDistance && `${formattedDistance} km • `}
          {formatAddressToCityAndProvinceOnly(address)}
        </p>
      </div>
    </button>
  );

  const desktopView = (
    <a
      className="relative flex w-[320px] flex-col justify-between gap-4 overflow-hidden rounded-lg border border-neutral-300 bg-white p-5 text-left shadow-providerCard lg:w-[350px]"
      onClick={handleGoToClinic}
      data-cy="provider-card"
    >
      {SHOW_FAVOURITES && isProviderFavourite && (
        <div className="absolute right-4">
          <FavouriteBadge />
        </div>
      )}
      <div className="h-20 w-20 rounded-md bg-gray-100">
        <img src={providerImage} alt={name} className="h-20 w-20 rounded-md object-cover" />
      </div>
      <div className="flex flex-col gap-y-3">
        <div className="flex flex-col">
          <p className="mb-1 line-clamp-2 text-base font-semibold text-neutral-600">
            {displayName}
          </p>
          <p className="font-regular line-clamp-2 text-sm text-neutral-500">{branch}</p>
        </div>
        <div className="flex flex-col">
          <div className="flex gap-x-2">
            <ClockIcon height={20} width={20} className="text-primary-300" />
            <p className="mb-1 truncate text-sm text-neutral-600">{officeHours ?? ''}</p>
          </div>
          {!!address && (
            <div className="flex items-center gap-x-2">
              <MapPinIcon height={20} width={20} className="flex-shrink-0 text-primary-300" />
              <div className="overflow-hidden truncate">
                <p className="text-sm text-neutral-600" title={formatCompleteAddress(address)}>
                  {formatAddressToCityAndProvinceOnly(address)}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <Button variant="primary" className="h-[52px] w-full">
        View Services
      </Button>
    </a>
  );

  return isMobile ? mobileView : desktopView;
}

export default ProviderCard;
