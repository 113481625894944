import React, { Fragment } from 'react';

import Link from 'next/link';

import { EventName, trackEvent } from '@mwell-healthhub/commons';

import { SERVICE_CARD_DATA, ServiceCardType } from '../../constants/serviceCardData';
import ServiceCard from '../atoms/ServiceCard';

const ServiceCards = () => {
  const displayCards = SERVICE_CARD_DATA;

  const handleServiceCardClick = (serviceCard: ServiceCardType) => {
    trackEvent(EventName.CLICK_HOME_QUICKLINK, {
      serviceName: serviceCard.title,
    });
  };

  return (
    <Fragment>
      <div className="my-10 grid w-full grid-cols-3 grid-rows-3 justify-center gap-4 sm:flex sm:flex-wrap sm:gap-[15px] lg:mt-[44px] xl:mb-[44px] xl:justify-between">
        {displayCards.map((data, index) => (
          <Link key={index} href={data.link} onClick={() => handleServiceCardClick(data)}>
            <ServiceCard
              imageUrl={data.imageUrl}
              iconUrl={data.iconUrl}
              title={data.title}
              imgPosition={data.imgPosition}
            />
          </Link>
        ))}
      </div>
    </Fragment>
  );
};

export default ServiceCards;
